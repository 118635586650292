import Model from './_base';

export default class Contract extends Model {
    static entity = 'upload';
    
    constructor() {
        super(Contract.entity);
    }

    static getCompanyContractFile(companyCode, key){
      return Model.axios.get(this.url('/' + companyCode + '/' + key), { responseType: 'blob' });
    }
  
    static getTermOfSystem(companyCode){
      return Model.axios.get(this.url('/' + companyCode + '/getTermsOfSystem'), { responseType: 'blob' });
    }
}