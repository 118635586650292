<template>
  <b-modal :id="modalId" @shown="load"  size="lg" hide-footer @hide="hide">
    <template v-slot:modal-title>
      契約書の確認
    </template>
    <div v-if="contract && getFileStatus==='success'">
      <div class="page-title">
        <h3>{{contract.contractName}}</h3>
        <div class="control">
          <a v-on:click="download()" href="javascript:void(0)">
            <font-awesome-icon :icon="['fas','file-download']"></font-awesome-icon>
            {{contract.contractName}}をダウンロード
          </a>
        </div>
      </div>
      <div class="contract-text">
          <iframe id="contractFileWindow" style="width:100%; height: 400px">
            <p>このブラウザーでは契約書を表示出来ません。上記のダウンロードボタン押して契約書を見てください。</p>
          </iframe>
      </div>
      <div class="controls text-center" v-if="!contract.contractSign || contract.contractSign.signStatus == -1">
        <j-button class="mt-3" variant="primary" @click="accept()">
          <font-awesome-icon :icon="['far','circle']"></font-awesome-icon>
          署名する
        </j-button>
        <j-button class="mt-3 ml-4" variant="danger" @click="close()">
          <font-awesome-icon :icon="['fas','times']"></font-awesome-icon>
          署名しない
        </j-button>
      </div>
      <div class="controls clearfix text-center position-relative" v-if="contract.contractSign">
        <j-button class="mt-3" variant="light" @click="close()">
          <font-awesome-icon :icon="['fas','times']"></font-awesome-icon>
          閉じる
        </j-button>
        <j-button v-if="contract.contractSign.signStatus === 1 && !isRegistrationWizard" class="mt-3 position-absolute" style="right:0;" variant="link" @click="reject()">
          解約する
        </j-button>
      </div>      
    </div>
    <div v-else-if="getFileStatus==='waiting'">
      <p class="text-info">契約書を取得中です。</p>
    </div>
    <div v-else-if="getFileStatus==='failed'">
      <p class="text-danger">契約書を取得できませんでした</p>
    </div>
  </b-modal>
</template>

<script>
import Contract from '@/model/Contract.js';
import CompanyInfo from '@/model/CompanyInfo.js';

export default {
  props: ['modalId','contract', 'isRegistrationWizard'],
  data: function(){
    return {
      contractFile: null,
      getFileStatus:"waiting"
    }
  },
  methods:{
    accept(){
      CompanyInfo.signCompanyContract(this.$store.state.user.companyCode, {
        contractCode: this.contract.contractCode,
        accepted: true,
        contractId: this.contract.id
      }).then(() => {
        this.$successMessage('契約書の署名処理を開始しました。');
        
        // Releasing ObjectURL resource
        URL.revokeObjectURL(document.getElementById("contractFileWindow").src);
        
        this.$bvModal.hide(this.modalId);  
        this.$emit('updated');
      }).catch(reason => {
        if(reason.response.data.message == "Not yet setting suo_user_id"){
          this.$errorMessage("システム処理中です。しばらくしてからやり直してください。", {reason});
        }
        else{
          this.$errorMessage(undefined, {reason});
        }
      });
    },
    close(){
      this.$bvModal.hide(this.modalId);  
    },
    hide(){
      // Releasing ObjectURL resource
      URL.revokeObjectURL(document.getElementById("contractFileWindow").src);
      this.$emit('hidden');
      this.$emit('updated');
    },
    reject(){
      
      this.$bvModal.msgBoxConfirm('特約をご解約すると、バイヤ企業との該当品目における取引はできなくなりますが、本当によろしいですか？', {
          title: 'ご解約手続き',
          size: 'lg',
          buttonSize: 'md',
          okVariant: 'primary',
          okTitle: 'はい',
          cancelVariant: 'light',
          cancelTitle: 'いいえ',
          footerClass: 'p-2 text-center',
          hideHeaderClose: false,
          centered: true
        }).then(value => {
          if(value){
            CompanyInfo.signCompanyContract(this.$store.state.user.companyCode, {
              contractCode: this.contract.contractCode,
              accepted: false,
              contractId: this.contract.id
            }).then(() => {
              // Releasing ObjectURL resource
              URL.revokeObjectURL(document.getElementById("contractFileWindow").src);

              this.$successMessage('契約書を解約しました。');
              this.$bvModal.hide(this.modalId);  
              this.$emit('updated');
            }).catch(reason => this.$errorMessage(undefined, {reason}));
          }
        }).catch(err => {        
          this.$errorMessage(undefined, {reason: err});
        });
    },
    load(){ 
      this.getFileStatus="waiting"
      let fileKey = this.contract.contractFileKey;
      if(this.contract.contractSign && this.contract.contractSign.signStatus === 1){
        fileKey = this.contract.contractSign.signedFileKey;
      }
      Contract.getCompanyContractFile(this.$store.state.user.companyCode, fileKey).then(result => {
        this.getFileStatus="success";
        setTimeout(function(file){
          const blob = new Blob([file.data], { type: 'application/pdf' })
          document.getElementById("contractFileWindow").src = URL.createObjectURL(blob);
          document.getElementById("contractFileWindow").download = result.headers['content-disposition'].split("filename=")[1];
        },200, result);
      }).catch(reason => {
        //this.$errorMessage(undefined, {reason})
        console.log(reason);
        this.getFileStatus="failed"
      });
    },
    download(){
      let fileKey = this.contract.contractFileKey;
      if(this.contract.contractSign && this.contract.contractSign.signStatus === 1){
        fileKey = this.contract.contractSign.signedFileKey;
      }
      Contract.getCompanyContractFile(this.$store.state.user.companyCode, fileKey).then(result => {
        const blob = new Blob([result.data], {type: "application/pdf"});
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = decodeURI(result.headers['content-disposition'].split("filename=")[1]);
        link.click();
        URL.revokeObjectURL(link.href);
        this.getFileStatus="success";
      }).catch(reason => this.$errorMessage(undefined, {reason}));
    }
  }
}
</script>

<style scoped lang="scss">
  .contract-text{
    border: 1px solid $biz-primary-light;
    padding: 1rem;
  }
</style>