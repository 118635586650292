<template>
  <div>
    <ul class="biz-breadcrumb" v-if="!isRegistrationWizard">
      <li><router-link to="/supplier/manage">システム管理</router-link></li>
      <li><a>契約管理</a></li>
    </ul>
    <div class="page">
      <div class="page-title" v-if="!isRegistrationWizard">
        <h1>システム利用規約・取引約款への署名</h1>
      </div>
      <div class="card biz-primary mt-4">
        <div class="card-header">署名待ち</div>
        <div class="card-body">
          <template v-if="waiting.filter(w => !w.destinationCode).length > 0">
            <div class="sub-title"><h2>ビズハイウェイ</h2><p>システム利用規約・取引約款に電子署名を行っていただきます。</p>    </div>
            <div class="mx-3 mt-3">
              <ContractItem :contract="c" v-for="c in waiting.filter(w => !w.destinationCode)" v-bind:key="c.id" @select="onSelect"/>
            </div>
          </template>
          <template v-if="waiting.filter(w => w.destinationCode).length > 0">
            <div class="sub-title"><h2>取引バイヤ企業</h2><p>システム利用規約・取引約款に電子署名を行っていただきます。</p>    </div>
            <div class="mx-3 mt-3">
              <ContractItem :contract="c" v-for="c in waiting.filter(w => w.destinationCode)" v-bind:key="c.id" @select="onSelect"/>
            </div>
          </template>
          <template v-else>
            署名済み契約がありません。
          </template>
        </div>
      </div>
      <div class="card biz-primary mt-4">
        <div class="card-header">署名済み</div>
        <div class="card-body">
          <template v-if="done.filter(d => !d.destinationCode).length > 0">
            <div class="sub-title">
              <h2>ビズハイウェイ</h2><p>システム利用規約・取引約款に電子署名を行っていただきます。</p>
            </div>
            <div class="mx-3 mt-3">
              <ContractItem :contract="c" v-for="c in done.filter(d => !d.destinationCode)" v-bind:key="c.id" @select="onSelect"/>
            </div>
          </template>
          <template v-if="done.filter(d => d.destinationCode).length > 0">
            <div class="sub-title">
              <h2>取引バイヤ企業</h2><p>システム利用規約・取引約款に電子署名を行っていただきます。</p>
            </div>
            <div class="mx-3 mt-3">
              <ContractItem :contract="c" v-for="c in done.filter(d => d.destinationCode)" v-bind:key="c.id"  @select="onSelect"/>
            </div>
          </template>
          <template v-else>
            署名済み契約がありません。
          </template>
        </div>
      </div>

      
      <div class="card biz-primary mt-4" v-if="!isRegistrationWizard">
        <div class="card-header">
          解約済み
        </div>
        <div class="card-body">
          <template v-if="cancelled.filter(d => !d.destinationCode).length > 0">
            <div class="sub-title">
              <h1>ビズハイウェイ</h1><p>システム利用規約・取引約款に電子署名を行っていただきます。</p>
            </div>
            <div class="mx-3 mt-3">
              <ContractItem :contract="c" v-for="c in cancelled.filter(d => !d.destinationCode)" v-bind:key="c.id" @select="onSelect"/>
            </div>
          </template>
          <template v-if="cancelled.filter(d => d.destinationCode).length > 0">
            <div class="sub-title">
              <h1>取引バイヤ企業</h1><p>システム利用規約・取引約款に電子署名を行っていただきます。</p>
            </div>
            <div class="mx-3 mt-3">
              <ContractItem :contract="c" v-for="c in cancelled.filter(d => d.destinationCode)" v-bind:key="c.id"  @select="onSelect"/>
            </div>
          </template>
          <template v-else>
            解約済み契約がありません。
          </template>
        </div>
      </div>

      
      <div class="card biz-primary mt-4" style="display:none;">
        <div class="card-header">
          締結対象外
        </div>
        <div class="card-body">
          <div v-if="excluded.length > 0">
            <ContractItem :contract="c" v-for="c in excluded" v-bind:key="c.id"  @select="onSelect"/>
          </div>
          <div v-else>
            <ContractItem empty-text="締結対象外契約がありません。"/>
          </div>
          
        </div>
      </div>
      
    </div>
    <div class="mt-4 p-4" v-if="isRegistrationWizard">
      <!-- <p class="text-center">
        <j-button variant="primary" size="lg" buttonType="button" @click.prevent="finish">
          〇引き続き
        </j-button>
      </p> -->
    </div>
    <ContractDetail modal-id="contract-detail" :isRegistrationWizard="isRegistrationWizard" :contract="selectedContract" @updated="refresh" @hidden="restartRefresh"></ContractDetail>
  </div>
</template>

<style scoped lang="scss">
  
</style>
<script> 
  import CompanyInfo from '@/model/CompanyInfo.js';
 
  import ContractItem from '@/components/contract/ContractItem.vue';
  import ContractDetail from '@/components/contract/ContractDetail.vue';
  
  export default {
    props: ["isRegistrationWizard"],
    components: { ContractItem, ContractDetail },
    data: () => ({
      waiting: [],
      done: [],
      cancelled: [],
      excluded: [],
      selectedContract: null,
      refreshInterval: null
    }),
    mounted: function(){
      this.refresh();
      //this.refreshInterval = setInterval(this.refresh, 10000);
    },
    beforeDestroy: function(){
      //clearInterval(this.refreshInterval);
    },
    methods: {
      async finish(){
        let popupRes = true;
        if(this.waiting.length > 0){
          const h = this.$createElement;
          const messageVNode = h('div', {class:[]}, [
            h('p', {class:['text-center']}, ['すべての特約・約款に署名した場合に「保存」が可能になります。']),
            h('p', {class:['text-center']}, ['署名をしない「特約・約款」が表示されている場合は、取扱い品目カテゴリの選択を修正する必要があります。']),
            
          ])
          popupRes = await this.$bvModal.msgBoxConfirm(messageVNode, {
            okTitle: "保存",
            cancelTitle: "キャンセル"
          })
        }
        if(popupRes){
          this.$router.push('/');
        }
      },
      restartRefresh(){
        this.refreshInterval = setInterval(this.refresh, 10000);
      },
      onSelect(contract){
        this.selectedContract = contract;
        this.$bvModal.show('contract-detail')
        clearInterval(this.refreshInterval);
      },
      async refresh(){
        try{
          this.selectedContract = null;
          let result = await CompanyInfo.getCompanyContracts(this.$store.state.user.companyCode);
          let dests = JSON.parse(JSON.stringify(result.data));
           this.waiting = dests 
            .filter(dest => {
              return dest.contracts.filter(contract => !contract.contractSign || contract.contractSign.signStatus === 0).length > 0;
            }).map(dest => {
              dest.contracts = dest.contracts.filter(contract => !contract.contractSign || contract.contractSign.signStatus === 0);
              return dest;
            });
          dests = JSON.parse(JSON.stringify(result.data));  
          this.cancelled = dests
            .filter(dest => {
              return dest.contracts.filter(contract => contract.contractSign ? contract.contractSign.signStatus === 2 : false).length > 0;
            }).map(dest => {
              dest.contracts = dest.contracts.filter(contract => contract.contractSign ? contract.contractSign.signStatus === 2 : false);
              return dest;
            });
          dests = JSON.parse(JSON.stringify(result.data));  
          this.done = dests
            .filter(dest => {
              return dest.contracts.filter(contract => contract.contractSign ? contract.contractSign.signStatus === 1 : false).length > 0;
            }).map(dest => {
              dest.contracts = dest.contracts.filter(contract => contract.contractSign ? contract.contractSign.signStatus === 1 : false);
              return dest;
            });

        }catch(reason){ this.$errorMessage(undefined, {reason}); }
      }
    }
  }
</script>