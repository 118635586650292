<template>
  <div :class="rowClass">
    <template v-if="emptyText">
      {{emptyText}}
    </template>
    <template v-else>
      <table class="table bg-white table-bordered">
        <colgroup>
          <col v-if="contract.destinationCode" width=""/>
          <col width=""/>
          <col width=""/>
        </colgroup>
        <thead>
          <tr class="table-light">
            <th v-if="contract.destinationCode">バイヤ企業</th>
            <th>約款・特約</th>
            <th>署名完了日付</th>
            <th>有効開始日</th>
            <th>有効終了日</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="c in contract.contracts" v-bind:key="c.id">
            <td v-if="contract.destinationCode">{{contract.destinationName}}</td>
            <td>
              <a href="#" v-on:click="open(c)">
                <font-awesome-icon :icon="['fas','file-alt']"></font-awesome-icon>
                {{c.contractName}}
              </a><br/>
              <small class="font-size-sm" v-if="c.lowCategory && c.lowCategory.categoryName">
                関連する取扱品目カテゴリ：{{c.topCategory.categoryName + " ≫ " + c.midCategory.categoryName + " ≫ " + c.lowCategory.categoryName}}
              </small>
            </td>
            <td>
              <span v-if="!c.contractSign">«未署名»</span>
              <span v-else-if="c.contractSign.signStatus === 0">
                <font-awesome-icon class="animated" :icon="['fas','spinner']"></font-awesome-icon>
                «システム処理中»
              </span>
              <span v-else-if="c.contractSign.signStatus === 1">
                {{c.contractSign.signedDate | formatDate}}
              </span>
              <span v-else-if="c.contractSign.signStatus === 2">
                解約日時:{{c.contractSign.signedDate | formatDate}}
              </span>
            </td>
            <td>
              {{c.startDate | formatDate}}
            </td>
            <td>
              {{c.endDate | formatDate}}
            </td>
          </tr>
        </tbody>
      </table>
    </template>
  </div>
</template>

<script>
export default {
  props: ["contract", "rowClass", "emptyText"],
  created: function(){
    if(this.contract){
      for(let c of this.contract.contracts){
        console.log(c)
      }
    }
  },
  methods: {
    open(c){
      this.$emit('select', c);
    }
  }
}
</script>

<style>
  .animated{
    animation: spin 5s infinite linear;
    transform: rotate(0deg);
  }
  @keyframes spin {
    0%{
      transform: rotate(0deg);
    }
    100%{
      transform: rotate(360deg);
    }
  }
</style>